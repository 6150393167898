import {request} from './request'

export function examinedMessage(op) {
  return request({
    url: '/appo/message/examinedMessage',
    method: 'get',
    data: op,
    params: op,
  })
}
