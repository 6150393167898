import {requestUpload, request} from './request'

export function page(op) {
  return request({
    url: '/appo/appointments/page',
    method: 'get',
    data: op,
    params: op
  })
}

export function getMyInformation(op) {
  return request({
    url: '/appo/appointments/getByAppoUserId',
    method: 'get',
    data: op
  })
}

export function update(op) {
  return request({
    url: '/appo/appointments',
    method: 'put',
    data: op
  })
}

export function save(op) {
  return request({
    url: '/appo/appointments',
    method: 'post',
    data: op
  })
}

export function upload(op) {
  return requestUpload({
    url: '/file/upload',
    method: 'post',
    data: op
  })
}
