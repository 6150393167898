<template>
  <div class="infor_content">
    <van-nav-bar title="相亲资料" left-text="返回" left-arrow 
      @click-left="toInformationsListPage">
      <template #right>
        <van-icon name="search" size="18" />
      </template>
    </van-nav-bar>
    <!-- <div class="info"><span @click="informationsListPage"><i class="iconfont icon-back-m"></i>基本信息</span></div> -->
    <van-notice-bar
      left-icon="volume-o"
      :text="examinedText"
    />
    <van-notice-bar 
      v-if="isMessageShow"
      :text="message.text" />
      
    <van-form @submit="onSubmit" ref="form">
      <van-field
        v-model="infor.name"
        name="昵称"
        label="昵称"
        placeholder="昵称"
        autocomplete="off"
        :rules="[{ required: true, message: '请填写昵称' }]"
      />
      <van-field name="radio11" label="性别">
        <template #input>
          <van-radio-group v-model="infor.gender" direction="horizontal">
            <van-radio name="1">男</van-radio>
            <van-radio name="2">女</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-model="infor.phone"
        type="tel" 
        name="手机"
        label="手机"
        placeholder="手机"
        autocomplete="off"
        :rules="[{ required: true, message: '请填写手机' }]"
      />
      <van-field
        v-model="infor.wechatCode"
        name="微信"
        label="微信"
        placeholder="微信"
        autocomplete="off"
        :rules="[{ required: true, message: '请填写微信' }]"
      />
      <!-- <van-field
        v-model="infor.idCard"
        name="身份证"
        label="身份证"
        placeholder="身份证"
        :rules="[{ required: true, message: '请填写身份证' }]"
      /> -->
      <van-field
        v-model="infor.height"
        type="number"
        name="身高"
        label="身高"
        placeholder="身高"
        autocomplete="off"
        :rules="[{ required: true, message: '请填写身高' }]"
      />
      <van-field
        v-model="infor.weight"
        type="number"
        name="体重"
        label="体重(斤)"
        placeholder="体重(斤)"
        :rules="[{ required: true, message: '请填写体重' }]"
      />
      <van-field
        readonly
        clickable 
        v-model="infor.education"
        name="学历"
        label="第一学历"
        placeholder="学历"
        autocomplete="off"
        :rules="[{ required: true, message: '请填写学历' }]"
        @click="showEducation = true;"
      />
      <van-popup v-model:show="showEducation" position="bottom">
        <van-picker
          title="学历"
          show-toolbar
          :default-index="educationIndex"
          :columns="infor.educationColumns"
          @confirm="educationOnConfirm"
          @cancel="showEducation = false"
        />
      </van-popup>
      <van-field
        v-model="infor.occupation"
        name="职业"
        label="职业"
        placeholder="职业"
        autocomplete="off"
        :rules="[{ required: true, message: '请填写职业' }]"
      />
      <van-field
        v-model="infor.salary"
        type="number"
        name="年薪(万)"
        label="年薪(万)"
        placeholder="年薪(万)"
        autocomplete="off"
        :rules="[{ required: true, message: '请填写年薪(万)' }]"
      />
      <van-field
        v-model="infor.house"
        name="房子"
        label="房子"
        placeholder="在哪里/多少平方"
        autocomplete="off"
        :rules="[{ required: true, message: '请填写房子' }]"
      />
      <van-field
        v-model="infor.car"
        name="车子"
        label="车子"
        placeholder="什么车子"
        autocomplete="off"
        :rules="[{ required: true, message: '请填写什么车子' }]"
      />
      
      <van-field name="radio" label="是否独生">
        <template #input>
          <van-radio-group v-model="infor.onlySon" direction="horizontal">
            <van-radio name="1">独生</van-radio>
            <van-radio name="2">非独生</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      
      <van-field
        readonly
        clickable 
        v-model="infor.birthday"
        name="生日"
        label="生日"
        placeholder="生日"
        :rules="[{ required: true, message: '请填写生日' }]"
        @click="showBirthday = true;"
      />
      <van-popup v-model:show="showBirthday" position="bottom">
        <van-datetime-picker
          v-model="birthdayCurrentDate"
          type="date"
          title="选择生日"
          :min-date="birthdayMinDate"
          :max-date="birthdayMaxDate"
          @confirm="birthdayOnConfirm"
          @cancel="showBirthday = false"
          :columns-order="['year', 'month', 'day']"
          :formatter="formatter"
        />
      </van-popup>
      <van-field
        readonly
        clickable 
        v-model="infor.marriage"
        name="婚姻"
        label="婚姻"
        placeholder="婚姻"
        :rules="[{ required: true, message: '请填写婚姻' }]"
        @click="showMarriage = true;"
      />
      <van-popup v-model:show="showMarriage" position="bottom">
        <van-picker
        title="婚姻"
        show-toolbar
        :default-index="marriageIndex"
        :columns="infor.marriageColumns"
        @confirm="marriageOnConfirm"
        @cancel="showMarriage = false"
        />
      </van-popup>
      <van-field
        readonly
        clickable 
        v-model="infor.nativePlace"
        name="籍贯"
        label="籍贯"
        placeholder="籍贯"
        :rules="[{ required: true, message: '请填写籍贯' }]"
        @click="showNativePlace = true;"
      />
      <van-popup v-model:show="showNativePlace" position="bottom">
        <van-area title="籍贯" :area-list="areaList" 
        :value="infor.nativePlaceV"
        @confirm="nativePlaceOnConfirm"
        @cancel="showNativePlace = false"
        />
      </van-popup>
      <van-field
        readonly
        clickable 
        v-model="infor.liveCity"
        name="居住"
        label="居住"
        placeholder="居住"
        :rules="[{ required: true, message: '请填写居住' }]"
        @click="showLiveCity = true;"
      />
      <van-popup v-model:show="showLiveCity" position="bottom">
        <van-area title="居住" :area-list="areaList" 
        :value="infor.liveCityV"
        @confirm="liveCityOnConfirm"
        @cancel="showLiveCity = false"
        />
      </van-popup>
      <van-field
        v-model="infor.selfSituation"
        type="textarea"
        name="自身情况"
        label="自身情况"
        placeholder="车房,学习,工作,一本,特殊才能,留学等等,把自身有优势的地方写出来"
        maxlength="400"
        show-word-limit
        :rules="[{ required: false, message: '请填写自身情况' }]"
      />
      <van-field
        v-model="infor.parentsSituation"
        type="textarea"
        name="父母情况"
        label="父母情况"
        placeholder="父母工作和养老情况,有兄弟姐妹读书或者已工作或者已结婚"
        maxlength="400"
        show-word-limit
        :rules="[{ required: true, message: '请填写自身情况' }]"
      />
      <van-field
        v-model="infor.hobby"
        type="textarea"
        name="爱好"
        label="爱好"
        placeholder="宠物,看书,刷剧,具体的运动,具体的游戏等等"
        maxlength="400"
        show-word-limit
        :rules="[{ required: true, message: '请填写爱好' }]"
      />
      <van-field
        v-model="infor.requirements"
        type="textarea"
        autosize= "{minHeight: 350}"
        name="择偶要求"
        label="择偶要求"
        placeholder="要求: 年龄:90-95 身高:175+ 年薪15W+ 是否独子 婚姻 颜值等等"
        maxlength="600"
        show-word-limit
        :rules="[{ required: true, message: '请填写择偶要求' }]"
      />
      <!-- <van-field
        name="身份证正面"
        label="身份证正面"
        placeholder="身份证正面"
      >
        <template #input>
          <van-uploader 
          v-model="infor.idCardPicFileList" 
          :after-read="idCardPicAfterRead"
          :max-count="1"
          accept="image/png, image/gif, image/jpeg"
          :max-size="200 * 1024" @oversize="onOversize"
          multiple />
        </template>
      </van-field>
      
      <van-field
        name="身份证背面"
        label="身份证背面"
        placeholder="身份证背面"
      >
        <template #input>
          <van-uploader 
          v-model="infor.idCardPicBFileList" 
          :after-read="idCardPicBAfterRead"
          :max-count="1"
          accept="image/png, image/gif, image/jpeg"
          :max-size="200 * 1024" @oversize="onOversize"
          multiple />
        </template>
      </van-field> 
      <van-field
        name="学历照片"
        label="学历照片"
        placeholder="学历照片"
      >
        <template #input>
          <van-uploader 
          v-model="infor.educationPicFileList" 
          :after-read="educationPicAfterRead"
          :max-count="1"
          accept="image/png, image/gif, image/jpeg"
          :max-size="200 * 1024" @oversize="onOversize"
          multiple />
        </template>
      </van-field> -->
      <van-field
        readonly
        clickable 
        v-model="infor.showPhotoText"
        name="显示照片"
        label="显示照片"
        placeholder="是否显示个人照片"
        autocomplete="off"
        :rules="[{ required: true, message: '请选择是否显示个人照片' }]"
        @click="showShowPhoto = true;aa()"
      />
      <van-popup v-model:show="showShowPhoto" position="bottom">
        <van-picker
          title="是否显示个人照片"
          show-toolbar
          :default-index="showPhotoIndex"
          :columns="infor.showPhotoColumns"
          @confirm="showPhotoOnConfirm"
          @cancel="showShowPhoto = false"
        />
      </van-popup>
      <van-field
        name="个人照片"
        label="个人照片"
        placeholder="个人照片"
        :rules="[{ required: true, message: '请填写个人照片' }]"
      >
        <template #input>
          <van-uploader 
          v-model="infor.photoFileList" 
          :after-read="photoAfterRead"
          :max-count="3"
          accept="image/png, image/gif, image/jpeg"
          :max-size="1000 * 1024" @oversize="onOversize"
          multiple />
        </template>
      </van-field>
      
      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit" >提交</van-button>
      </div>
    </van-form>
    
  </div>
</template>
<script>
import { areaList } from '@vant/area-data';
import {upload, save, getMyInformation, update} from 'network/informations.js'
import {examinedMessage} from 'network/message.js'

import tokenFunc from './token.js'

export default {
  name: 'Informations',
  components: {
  },
  data() {
    return {
      areaList,
      infor:{
        name:'',
        gender:'1',
        phone:'',
        height:'',
        weight:'',
        education:'本科',
        educationColumns:['博士','研究生','本科','大专','其他'],
        occupation:'',
        salary:'',
        house:'',
        car:'',
        selfSituation:'',
        onlySon:'1',
        parentsSituation:'',
        hobby:'',
        requirements:'',
        birthday:'',
        marriage:'未婚',
        marriageColumns:['未婚','离异无孩','离异有孩'],
        nativePlace:'浙江省宁波市鄞州区',
        nativePlaceV:'330000,330200,330212',
        liveCity:'浙江省宁波市鄞州区',
        liveCityV:'330000,330200,330212',
        wechatCode:'',
        idCard:'',
        idCardPicRemove:'',
        idCardPic:'',
        idCardPicFileList:[],
        idCardPicB:'',
        idCardPicBFileList:[],
        educationPic:'',
        photo:'',
        photoRemove:'',
        photoFileList:[],
        showPhoto: 0,
        showPhotoText: '不显示',
        showPhotoColumns:['显示','不显示'],
        examined: '未审核'
      },
      message: {
        text: ''
      },
      showEducation:false,
      showMarriage:false,
      showBirthday:false,
      showNativePlace:false,
      showLiveCity:false,
      showShowPhoto:false,
      birthdayMinDate: new Date(1975, 0, 1),
      birthdayMaxDate: new Date(2055, 10, 1),
      birthdayCurrentDate:new Date(1995, 0, 1),
    }
  },
  computed: {
    isMessageShow() {
      return this.message.text && this.infor.examined!='已审核'
    },
    examinedText() {
      let ccc = "请联系小编尽快审核您的资料!"
      if(this.infor.examined=='未审核') {
        ccc ='您的相亲资料状态为: 未审核! 请联系小编尽快审核您的资料'
      } else if(this.infor.examined=='未通过') {
        ccc ='您的相亲资料状态为: 未通过!'
      } else {
        ccc ='您的相亲资料状态为: 已审核! 别人已经可以看到您的资料了!'
      }
      return ccc
    },
    educationIndex() {
      return this.infor.educationColumns.findIndex((v) => {
        return v == this.infor.education
      })
    },
    marriageIndex() {
      return this.infor.marriageColumns.findIndex((v) => {
        return v == this.infor.marriage
      })
    },
    showPhotoIndex() {
      return this.infor.showPhotoColumns.findIndex((v) => {
        return v == this.infor.showPhotoText
      })
    },
  },
  created() {
    const aa = this.$route.query
    this.init()
  },
  setup() {
    let {tokenTxt, gettersChangeToken, setTokenTxt} = tokenFunc()
    return {
      tokenTxt,
      gettersChangeToken,
      setTokenTxt,
    }
  },
  mounted() {
  },
  methods: {
    aa() {
      console.log(this.infor.photo);
    },
    init() {
      var promise = getMyInformation().then(res => {
        if(res.code == 0) {
          if(res.data) {
            Object.assign(this.infor, res.data )
            this.infor.onlySon= res.data.onlySon + ''
            this.infor.gender= res.data.gender + ''
            this.infor.photoFileList = this.infor.photo.split(',').map((item)=>{
              return {url: process.env.VUE_APP_BASE_API+'/api/file/'+item}
            })
            this.birthdayCurrentDate=new Date(res.data.birthday),
            this.photoBeforeDeleted()
            delete this.infor.deleted
            delete this.infor.orderNum
            
          }
          resolve(value);
        } else {
          this.$toast.success(res.msg);
          reject(error);
        }
      }).catch((error) => {})
    
      promise.then(() => {
        examinedMessage({receiverAppoId:this.infor.id, type: 'examined'}).then(res => {
          if(res.code == 0) {
            this.message.text = res.data.content
          } else {
            this.$toast.success({message: res.msg, duration: 2000});
          }
        }).catch((error) => {})
      })
    },
    toInformationsListPage() {
      this.$router.push({path: '/informationsList'});
    },
    onSubmit() {
      const formData = new FormData()
      Object.keys(this.infor).forEach(key => {
        formData.append(key, this.infor[key])
      })
      if(this.infor.id) {
        formData.set('examined', '未审核')
        update(formData).then(res => {
          if(res.code == 0) {
            // this.$toast.success({
            //   message: "修改成功, 请等待审核!",forbidClick: true,duration: 1500, 
            //   onClose: () => { this.$router.push({path: '/informationsList',query:{ id:'2'}});  },
            // });
            // this.infor.examined='请联系小编尽快审核您的资料'
            this.$dialog.alert({
              title: '注意事项',
              message: '修改成功, 请联系小编等待审核!',
            }).then(() => {
              this.$router.push({path: '/informationsList',query:{ id:'2'}});
            });
          } else {
            this.$toast.success(res.msg);
          }
        })
        .catch((error) => {})
      } else {
        save(formData).then(res => {
          if(res.code == 0) {
            // this.$toast.success({
            //   message: "注册成功, 请等待审核!",forbidClick: true,duration: 1500, 
            //   onClose: () => { this.$router.push({path: '/informationsList',query:{ id:'2'}});  },
            // });
            // this.infor.examined='请联系小编尽快审核您的资料'
            this.$dialog.alert({
              title: '注意事项',
              message: '添加成功, 请联系小编等待审核!',
            }).then(() => {
              this.$router.push({path: '/informationsList',query:{ id:'2'}});
            });
          } else {
            this.$toast.success(res.msg);
          }
        })
        .catch((error) => {})
      }
      
    },
    idCardPicAfterRead(file) {
      let formData = new FormData()
      formData.append("file",file.file)
      upload(formData).then(res => {
        this.infor.idCardPic = res.url
      })
      .catch((error) => {})
    },
    idCardPicBAfterRead(file) {
      let formData = new FormData()
      formData.append("file",file.file)
      upload(formData).then(res => {
        this.infor.idCardPicB = res.url
      })
      .catch((error) => {})
    },
    educationPicAfterRead(file) {
      let formData = new FormData()
      formData.append("file",file.file)
      upload(formData).then(res => {
        this.infor.educationPic = res.url
      })
      .catch((error) => {})
    },
    photoBAfterRead(file) {
      let formData = new FormData()
      formData.append("file",file.file)
      upload(formData).then(res => {
        this.infor.photoB = res.url
      })
      .catch((error) => {})
    },
    photoAfterRead(file) {
      let formData = new FormData()
      formData.append("file",file.file)
      upload(formData).then(res => {
        if(this.infor.photo) {
          this.infor.photo += ","
        }
        this.infor.photo += res.url
        this.infor.photoFileList[this.infor.photoFileList.length-1].url = res.url
        this.photoBeforeDeleted()
      }).catch((error) => {})
    },
    photoBeforeDeleted() {
      this.infor.photoFileList.forEach((item,index) => {
        item.deletable = true,
        item.beforeDelete = (d) => {
          const itemUrl = item.url
          const allUrl = itemUrl.replace(process.env.VUE_APP_BASE_API+'/api/file/','')
          this.infor.photo = this.infor.photo.replace(','+allUrl+',',',').replace(allUrl+',','').replace(','+allUrl,'').replace(allUrl,'')
          
          this.infor.photoFileList.splice((index), 1)
          this.photoBeforeDeleted()
        }
      })
    },
    educationOnConfirm(value, index) {
      this.infor.education = value
      this.showEducation = false
    },
    marriageOnConfirm(value, index) {
      this.infor.marriage = value
      this.showMarriage = false
    },
    birthdayOnConfirm(date) {
      this.infor.birthday = this.formatDate(date);
      this.showBirthday = false
    },
    nativePlaceOnConfirm(value, index) {
      console.log(value);
      this.infor.nativePlace = value[0].name + value[1].name + value[2].name
      this.infor.nativePlaceV = value[0].code +',' + value[1].code +',' + value[2].code
      this.showNativePlace = false
    },
    liveCityOnConfirm(value, index) {
      this.infor.liveCity = value[0].name + value[1].name + value[2].name
      this.infor.liveCityV = value[0].code +',' + value[1].code +',' + value[2].code
      this.showLiveCity = false
    },
    showPhotoOnConfirm(value, index) {
      this.infor.showPhoto = value == '显示'? 1: 0
      this.infor.showPhotoText = value
      this.showShowPhoto = false
    },
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    formatter(type, val) {
      if (type === 'year') {
        return val + '年';
      }
      if (type === 'month') {
        return val + '月';
      }
      if (type === 'day') {
        return val + '日';
      }
      return val;
    },
    onOversize(file) {
      this.$toast({
        message: "文件大小不能超过 1000KB",
        duration: 2000,
      });
    },
  },
}
</script>
<style scoped>
.infor_content {
  color: black;
  font-size: 18px;
  background-color: #F7F8FA;
}
.info {
  color: #8C99A0;
  font-size: 15px;
  line-height: 50px;
  padding-left: 16px;
}
.item {
  display:flex;
  justify-content: space-around;

  border-bottom: 1px solid #e3e3e3;
  line-height: 50px;
}
.content_left {
  width: 19vw;
}
.content_right {
  width: 79vw;
  text-align: right;
}
.inforStar {
  font-size: 10px;
  color: red;
}
.input {
  text-align: right;
  padding: 0;
  border: 0;
  width:100%;
  height: 48px;
}
.input:focus {
  outline: none;
}
</style>